import React, { createContext, useContext, useState } from "react";
import moment from "moment";

const DateContext = createContext();

export const DateProvider = ({ children }) => {


  let date = localStorage.getItem('GlobalDate') ? moment(localStorage.getItem('GlobalDate')).toDate() : new Date() ;  
  console.log("localStorage.getItem('GlobalDate') ",localStorage.getItem('GlobalDate') ,moment(localStorage.getItem('GlobalDate')).toDate(),date)
  const [selectedDate,setdate] = useState(moment(date)); // Store as moment object

  function setSelectedDate(val){
    console.log("setSelectedDate  val",val,moment(val).format('YYYY-MM-DD'));
    setdate(val);
    localStorage.setItem('GlobalDate',moment(val).format('YYYY-MM-DD'))

  }


  return (
    <DateContext.Provider value={{ selectedDate, setSelectedDate }}>
      {children}
    </DateContext.Provider>
  );
};

export const useDate = () => useContext(DateContext);
